// color variants
@import 'themes-vars.module.scss';

// third-par
@import ' ~react-perfect-scrollbar/dist/css/styles.css';
@import ' ~slick-carousel/slick/slick.css';
@import ' ~slick-carousel/slick/slick-theme.css';

@import ' ~react-18-image-lightbox/style.css';
@import 'react-responsive-carousel/lib/styles/carousel.min.css';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
    color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //
/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: rgb(226, 226, 249) inherit;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: rgb(226, 226, 249);
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(226, 226, 249);
    border-radius: 20px;
    border: 3px solid rgb(226, 226, 249);
}

.scrollbar-container {
    .ps__rail-y {

        &:hover>.ps__thumb-y,
        &:focus>.ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }

    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container>.ps {
    &.ps--active-y>.ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;

        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }

    &.ps--scrolling-y>.ps__rail-y,
    &.ps--scrolling-x>.ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes bounce {

    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }

    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {

    0%,
    50%,
    100% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-10px);
    }

    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {

    0%,
    50%,
    100% {
        transform: translateX(0px);
    }

    25% {
        transform: translateX(-10px);
    }

    75% {
        transform: translateX(10px);
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

.preBuildDashBoard-slider {
    overflow: hidden;

    .slider {
        .slide {
            opacity: 0.5;
            display: flex;
            justify-content: center;

            .custom-slider {
                gap: 40px;
            }

            &.selected {
                opacity: 1;

                .custom-slider {
                    display: flex;
                    flex-direction: column;
                }
            }

            &:not(.selected) {
                transform: scale(0.7);
                transform-origin: top;
            }
        }
    }
}

.project-info {
    .project-slider {
        .slick-list {
            padding: 0 !important;
        }
    }

    .slick-slide {
        opacity: 0.05;
    }

    .slick-active {
        opacity: 0.2;
    }

    .slick-current {
        opacity: 1 !important;

        .MuiTypography-root {
            color: $primaryMain;
        }
    }
}

.ril__inner {
    direction: ltr;
}

.ReactModal__Overlay {
    z-index: 99999 !important;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 15px rgba(0, 230, 118, 0.6);
    }

    50% {
        box-shadow: 0 0 30px rgba(0, 230, 118, 0.3);
    }

    100% {
        box-shadow: 0 0 15px rgba(0, 230, 118, 0.6);
    }
}

@keyframes applyBox {
    0% {
        box-shadow: 0 0 15px rgba(121, 122, 121, 0.6);
    }

    50% {
        box-shadow: 0 0 30px rgba(130, 129, 129, 0.3);
    }

    100% {
        box-shadow: 0 0 15px rgba(103, 104, 104, 0.6);
    }
}

@keyframes fadeInScale {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pumpUp {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
        /* Icon grows slightly larger */
    }

    100% {
        transform: scale(1);
        /* Returns to normal size */
    }
}

.spinner {
    position: absolute;
    top: 40vh;
    left: 47vw;
    width: 150px;
    /* Updated to 300px */
    height: 150px;
    /* Updated to 300px */
    animation: spinner-y0fdc1 2.4s infinite ease;
    transform-style: preserve-3d;
}

.spinner>div {
    background-color: rgba(247, 150, 12, 0.2);
    height: 100%;
    position: absolute;
    width: 100%;
    border: 5px solid #f7960c;
    /* Updated border size proportionally */
}

.spinner div:nth-of-type(1) {
    transform: translateZ(-75px) rotateY(180deg);
    /* Updated translateZ */
}

.spinner div:nth-of-type(2) {
    transform: rotateY(-270deg) translateX(50%);
    transform-origin: top right;
}

.spinner div:nth-of-type(3) {
    transform: rotateY(270deg) translateX(-50%);
    transform-origin: center left;
}

.spinner div:nth-of-type(4) {
    transform: rotateX(90deg) translateY(-50%);
    transform-origin: top center;
}

.spinner div:nth-of-type(5) {
    transform: rotateX(-90deg) translateY(50%);
    transform-origin: bottom center;
}

.spinner div:nth-of-type(6) {
    transform: translateZ(75px);
    /* Updated translateZ */
}

@media (max-width: 576px) {
    .spinner {
        position: absolute;
        top: 45vh;
        left: 45vw;
        width: 50px;
        height: 50px;
    }

    .spinner div:nth-of-type(1) {
        transform: translateZ(-27px) rotateY(180deg);
    }

    .spinner div:nth-of-type(6) {
        transform: translateZ(27px);
    }

}


@keyframes spinner-y0fdc1 {
    0% {
        transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
    }

    50% {
        transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
    }

    100% {
        transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
    }
}